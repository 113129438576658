import type { ComponentProps } from 'react';
import {
  Notice,
  NoticeDescription,
  NoticeTitle,
} from '@/pages/QuickCreate/components/Notice';
import { Button, Flex } from '@/ui';
import { getErrorMessage } from './getErrorMessage';
import type { ErrorInput, PromptType } from './types';

type Props = ComponentProps<typeof Notice> & {
  error?: ErrorInput;
  type?: PromptType;
  onReset?: () => void;
};

export const QuickCreateErrorMessage = ({
  error,
  type,
  onReset,
  ...props
}: Props) => {
  const { title, message } = getErrorMessage(error, type);

  return (
    <Notice {...props}>
      <Flex align="center" gap="3" justify="space-between" w="100%">
        <NoticeTitle fontWeight={message ? undefined : 'normal'}>
          {title}
        </NoticeTitle>
        {onReset && (
          <Button
            colorScheme="white"
            size="sm"
            variant="solid"
            onClick={onReset}
          >
            Try again
          </Button>
        )}
      </Flex>
      {message && (
        <NoticeDescription dangerouslySetInnerHTML={{ __html: message }} />
      )}
    </Notice>
  );
};
